
import { mapGetters } from 'vuex'
import InnerPageTitle from '../Shared/InnerPageTitle/index.vue'
export default {
  components: { InnerPageTitle },
  props: {
    fast: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getRedirectUrl: 'getRedirectUrl',
      getAutoLogin: 'user/getAutoLogin',
    }),
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      alertBox: {
        status: false,
        message: '',
        variant: '',
      },
      loading: false,
    }
  },
  methods: {
    async SEND_TO_LOGIN(e) {
      e.preventDefault()
      if (this.loading) return
      this.loading = true
      this.alertBox = await this.$store.dispatch(
        'user/SEND_TO_LOGIN',
        this.form
      )
      if (this.alertBox.variant === 'success') {
        if (this.fast) return this.$emit('login:success')
        this.$router.push({
          path: this.localePath(this.$store.getters.getReturnUrl),
          query: { action: 'fast-buy' },
        })
      }
      this.loading = false
    },
    CHECK_AUTO_LOGIN() {
      if (!this.getAutoLogin.status) return
      this.form = {
        email: this.getAutoLogin.email,
        password: this.getAutoLogin.password,
      }
      this.$store.commit('user/REMOVE_AUTO_LOGIN')
      this.SEND_TO_LOGIN({ preventDefault: () => true })
    },
  },
  mounted() {
    this.CHECK_AUTO_LOGIN()
  },
}


import Login from '~/components/Login/index.vue'
import deauth from '~/middleware/deauth'
export default {
  components: { Login },
  middleware: [deauth],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title: `${this.$custom$t('login')}` + ' | ' + this.$store.state.settings.title,
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.settings.noImage,
        },
      ],
      meta: [
        { hid: 'og-type', property: 'og:type', content: 'website' },
        {
          hid: 'title',
          property: 'title',
          content:
            `${this.$custom$t('login')}` + ' | ' + this.$store.state.settings.title,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.settings.contents[
            this.$i18n.locale
          ].home.banner.overview.substring(0, 152),
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content:
            `${this.$custom$t('login')}` + ' | ' + this.$store.state.settings.title,
        },
        {
          hid: 'og-desc',
          property: 'og:description',
          content: this.$store.state.settings.contents[
            this.$i18n.locale
          ].home.banner.overview.substring(0, 152),
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home
              .image,
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home.url,
        },
        { hid: 't-type', name: 'twitter:card', content: 'summary_large_image' },
      ],
      script: [
        ...this.$store.state.settings.analyticSettings
          .filter((as) => as.key === 'script' && as.source === null)
          .map((asettings) => ({
            type: 'text/javascript',
            innerHTML: asettings.value,
          })),
        ...this.$store.state.settings.analyticSettings
          .filter((as) => as.key === 'script' && as.source !== null)
          .map((asettings) => ({
            type: 'text/javascript',
            src: asettings.source,
            innerHTML: asettings.value,
          })),
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
}
